<template>
  <a-row v-if="showStatus">
    <a-col :span="16">
      <a-input placeholder="给一块饼干！" v-model:value="form.cookie_name" />
    </a-col>
    <a-col :span="8">
      <a-button type="primary" @click="loginSubmit()" block>给你！</a-button>
    </a-col>
  </a-row>
  <a-row v-if="!showStatus">
    <a-col :span="24">
      <p style="color: #a6adb4">你好：{{ token }}</p>
    </a-col>
  </a-row>
</template>
<script>
import { getCurrentInstance, reactive, ref } from "vue";
import axios from "axios";
export default {
  setup() {
    var form = reactive({
      cookie_name: null,
    });
    var showStatus = ref(true);
    var token = ref(localStorage.getItem("token"));
    const { proxy } = getCurrentInstance();
    if (token.value !== null) {
      showStatus.value = false;
    }
    var loginSubmit = function () {
      axios
        .post("https://la.juhuan.store/api/adao/show_id_get2cookie_name", {
          cookie_name: form.cookie_name,
        })
        .then(function (response) {
          proxy.$common.token = response.data.data.token;
          showStatus.value = false;
          token.value = proxy.$common.token;
          localStorage.setItem("token", token.value);
        });
    };
    return {
      form,
      showStatus,
      loginSubmit,
      token,
    };
  },
};
</script>