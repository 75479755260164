import { createRouter,createWebHashHistory } from "vue-router";

import List from './components/List.vue'
import Page from './components/Page.vue'
import Login from './components/Login.vue'

const router = createRouter({
    history:createWebHashHistory(),
    routes:[
        {path: '/', component: List},
        {path: '/page/:id', component: Page},
        {path: '/login', component: Login},
    ]
})

export default router