<template>
  <ListShow />
</template>
<script>
import axios from "axios";
import { reactive, provide } from "vue";
import ListShow from "./ListShow.vue";
export default {
  components: {
    ListShow,
  },
  setup() {
    var res = reactive({
      list: [],
      count: 0,
      page:0,
      size:10,
    });
    axios
      .get("https://la.juhuan.store/api/adao/show_list?page="+res.page+"&size="+res.size)
      .then(function (response) {
        res.list = response.data.data.list;
        res.count = response.data.data.count;
      });
    provide("ListRes", res);
  },
};
</script>

<style>
header.ant-layout-header {
  color: black;
  background-color: #f0f2f5;
}

main.ant-layout-content {
  background-color: white;
}
div.push {
  background-color: white;
  height: 10rem;
}
ul.ant-pagination {
  background-color: white;
  text-align: center;
}
</style>