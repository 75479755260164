<template>
  <div class="ListShow">
    <a-row>
      <a-col :span="24" v-for="(item, index) in list" :key="index">
        <a-row>
          <a-col :span="8">
            <a-image :width="200" :src="item.img_url" />
          </a-col>
          <a-col :span="16" :style="{ padding: '0 50px', marginTop: '64px' }">
            <h1>
              <router-link :to="`/page/${item.id}`">{{
                item.title
              }}</router-link>
            </h1>
            <a-row>
              <a-col :span="16">
                <p style="text-align: left">简介：{{ item.content }}</p>
              </a-col>
              <a-col :span="8">
                <p>目标人数：{{ item.obj_count }}人</p>
                <p>当前人数：{{ item.now_count }}人</p>
              </a-col>
            </a-row>
          </a-col>
          <a-divider></a-divider>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { inject, toRefs, watch } from "vue";
export default {
  setup() {
    var res = inject("ListRes");
    watch(res, function () {
      for (var i = 0; i < res.list.length; i++) {
        if (res.list[i].rate_count != 0) {
          var value = res.list[i].rate_total / res.list[i].rate_count;
          Reflect.set(res.list[i], "rate_value", value);
        } else {
          Reflect.set(res.list[i], "rate_value", 0);
        }
      }
    });
    return {
      ...toRefs(res),
    };
  },
};
</script>
<style>
div.ListShow {
  text-align: center;
  margin: 10px 5px 10px 5px;
}
</style>