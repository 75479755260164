import { createApp } from 'vue'
import App from './App.vue'
import route from './route.js'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

import Common from './common.js'

let app = createApp(App)
app.config.globalProperties.$common = Common
console.log(localStorage.getItem('token'))

app
.use(Antd)
.use(route)
.mount('#app')