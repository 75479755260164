// import axios from 'axios'
const token = localStorage.getItem("token")
// const getToken = function() {
//     axios.get(
//         "https://la.juhuan.store/api/adao/show_id_get2ip"
//     ).then(function (response) {
//         this.token = response.data.data.token
//         console.log(this.token)
//         // localStorage.setItem('token', this.token)
//         // console.log(localStorage.getItem('token'))
//     })
// }

export default {
    token,
}