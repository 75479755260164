<template>
  <a-row>
    <a-col
      :span="8"
      style="text-align: center"
      :style="{ padding: '0 50px', marginTop: '64px' }"
    >
      <a-image :width="200" :src="data.img_url" />
    </a-col>
    <a-col
      :span="16"
      style="text-align: center"
      :style="{ padding: '0 50px', marginTop: '64px' }"
    >
      <a-typography>
        <a-typography-title>
          {{ data.title }}
        </a-typography-title>
        <a-typography-paragraph style="text-align: left">
          {{ data.content }}
        </a-typography-paragraph>
        <div style="height: 50px"></div>
        <a-row>
          <a-col :span="12" style="text-align: center">
            <p style="text-align: left">目标人数：{{ data.obj_count }}人</p>
            <p style="text-align: left">当前人数：{{ data.now_count }}人</p>
          </a-col>
          <a-col :span="12" style="text-align: center">
            <a-button type="primary" @click="followfun(data.id)" block>
              给我也整一个！！！
            </a-button>
          </a-col>
        </a-row>
      </a-typography>
    </a-col>
  </a-row>
</template>
<script>
import { useRoute } from "vue-router";
import axios from "axios";
import { toRefs, reactive, watch, getCurrentInstance } from "vue";
export default {
  setup() {
    let route = useRoute();
    let res = reactive({
      data: {},
      rate_value: 0,
    });
    const { proxy } = getCurrentInstance();
    watch(res.data, function () {
      console.log(res);
      if (res.data.rate_count != 0) {
        res.rate_value = res.data.rate_total / res.data.rate_count;
      } else {
        res.rate_value = 0;
      }
    });
    var show_get = function () {
      axios
        .get("https://la.juhuan.store/api/adao/show_get?id=" + route.params.id)
        .then(function (response) {
          res.data = response.data.data;
        });
    };
    var followfun = function (id) {
      let token = proxy.$common.token;
      if (token !== null) {
        console.log(token);
        axios
          .post("https://la.juhuan.store/api/adao/show_follow", {
            id: id,
            token: token,
          })
          .then(function (response) {
            if (response.data.data.status === true) {
              res.now_count += 1;
              alert("整了！！！");
            } else {
              alert("整过了，整过了");
            }
            show_get();
          });
      } else {
        alert("没有饼干！！！");
      }
    };
    show_get();
    return {
      ...toRefs(res),
      followfun,
    };
  },
};
</script>